//  -yzm

//other
let __system = {
  __activeid: "",
  __menu: [
  {
    pageFlag: false,
    id: "fst",
    title: "日常",
    icon: "el-icon-menu",
    children: [
    
    {
        pageFlag: false,
        id: "fstday",
        title: "业务",
        icon: "el-icon-menu",
        children: [
          {
            pageFlag: true,
            id: "ax_fst_voua",
            title: "单据",
            name: "ax_fst_voua",
            icon: "el-icon-document",
            component: "views/fst/AxFstVouaList.vue",
            componentdetail: "views/fst/AxFstVouaDetail.vue",
            visible: true
        }
     
      ],
      },
      
     
      
      
],
  }, ],
  __mdilist: [],
  set activeid(val) {
    this.__activeid = val;
  },
  get activeid() {
    return this.__activeid;
  },
  set menu(val) {
    this.__menu = val;
  },
  get menu() {
    return this.__menu;
  },
  set mdilist(val) {
    this.__mdilist = val;
  },
  get mdilist() {
    return this.__mdilist;
  }
}


let ld = (function (__system) {
  let _ld = {
    "system": __system,
  }
  return _ld;
})(__system)
export default ld